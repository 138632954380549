import React from 'react';
import PropTypes from 'prop-types';

// import Link from 'next/link';

import { NextSeo } from 'next-seo';
import { domainName, sortAlphabetically, staticAssetsPrefix, websiteUrl, appStoreUrl, playStoreUrl, podcastTypes } from '../lib/utils';
// import { FETCH_SUBSCRIBED_PODCASTS_ERROR } from '../actions/types';
// import AppCustomLink from '../components/Common/AppCustomLink';

// import { getRequiredPodcasts } from '../reducers';
// import { fetchLatestSubscribedEpisodes } from '../actions';
import { colors, spacing, minScreenSize, maxScreenSize } from '../lib/theme';
import { Col, Row } from 'antd';
// import EmptyState from '../components/Common/EmptyState';
import Podcast from '../components/Podcast/Podcast';
import FeatureCard from '../components/MainPage/FeatureCard';
// import * as gtag from '../lib/gtag';
import Testimonial from '../components/Common/Testimonial';
import LandingMain from '../components/Common/LandingMain';
import FAQ from '../components/Common/FAQ';
import LandingFeatures from '../components/Common/LandingFeatures';
import LandingTrending from '../components/Common/LandingTrending';
import { fetchTopChartsPodcasts } from '../actions';
import LandingGoals from '../components/Common/LandingGoals';

const testimonials = [
  { author: { name: 'Amelia Thompson', img: 'amelia.jpg' }, comment: 'This is the best podcast player app I have used in a while. It saves me time by showing the podcasts I might like. Excellent app overall.' },
  { author: { name: 'Steve Smith', img: 'steve.jpg' }, comment: 'Awesome podcast player. Any issues have been fixed almost immediately after feedback was sent - fantastic customer service. I recommend this app to all of my friends who are podcast listeners!' },
  { author: { name: 'Ethan Wilson', img: 'ethan.jpg' }, comment: 'I really loved the ability to organize podcasts by tags and genres. I can also add private notes to any episode. I am definitely keeping this!' },
];

// const limitPodcastsMobile = 10;



export async function getStaticProps(context) {
  try {

    let promises = [];
    podcastTypes.forEach((genreMap) => {
      promises.push(fetchTopChartsPodcasts(genreMap.id, 1, 10));
    })
    const results = await Promise.all(promises);
    return { props: { trendingPodcasts: results, } };
  } catch (err) {
    console.error(err);
    // throw new Error("Api call didn't return any data.");
    return { props: { trendingPodcasts: [] } };
  }
}

class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  setPageData() {
    // this.props.fetchLatestSubscribedEpisodes(Object.values(subscribedPodcasts));
  }

  // openBrowse = () => {
  //   window.open(`${domainName}top-charts`, '_self');
  // }

  render() {
    const { trendingPodcasts } = this.props;
    console.dir(trendingPodcasts);
    // const messageSubscribe = (
    //   <div>You haven&apos;t subscribed to any podcasts yet. Please <Link href='/search'><a>Search</a></Link> for your favorite podcast or
    //   <Link href='/browse'><a> Browse</a></Link> through top podcasts.</div>
    // )
    const webUrl = websiteUrl + '/top-charts';
    const webUrlDownload = webUrl + '?downloadApp=true';
    const titleSEO = 'Accelerated: Book Summary App';
    const descriptionSEO = 'Best book summary app for mobile and desktop devices. Discover 8000+ books and over hundred thousand key ideas.';
    return (
      <div>
        <NextSeo
          title={titleSEO}
          description={descriptionSEO}
          canonical='https://tedapp.ai/'
          openGraph={{
            title: titleSEO,
            description: 'Best book summary app for mobile and desktop devices. Discover 8000+ books and over hundred thousand key ideas.',
            type: 'website',
            locale: 'en_IE',
            url: 'https://tedapp.ai',
            images: [{
              url: 'https://tedapp.ai/explore/static/images/main-page/LandingLarge.png',
              alt: 'Best podcast player for all devices - web, ios, android, windows and mac OS',
              width: 922,
              height: 1209,
            }],
            site_name: 'Accelerated',
          }}
          twitter={{
            handle: '@tedapp',
            site: '@tedapp',
            cardType: 'summary_large_image',
          }}
        />
        {/* <Nav /> */}
        <header>
          <LandingMain />
        </header>
        <main>
          {/* <section className='app-section-1'>
            
          </section> */}
          {/* <section style={{ maxWidth: '1000px', margin: 'auto', marginBottom: '60px', position: 'relative', zIndex: '10', background: 'white', paddingTop: '12px' }}>
            <hr className='separator' />
            <div className='brand-logo' style={{ textAlign: 'center' }}>
              <img src={`${staticAssetsPrefix}/static/images/landing-logos/crunchbase-vector-logo.svg`} style={{ width: '150px', height: '100px', objectFit: 'cover' }} />
              <img src={`${staticAssetsPrefix}/static/images/landing-logos/product_Hunt_Logo.png`} style={{ width: '150px' }} />
              <img src={`${staticAssetsPrefix}/static/images/landing-logos/angellist.svg`} style={{ width: '150px' }} />
              <img src={`${staticAssetsPrefix}/static/images/landing-logos/betalist.png`} style={{ width: '150px' }} />
            </div>
            <hr className='separator' />
          </section> */}
          <section className='app-section-2'>
            <LandingFeatures />
          </section>
          <section className='app-section-3'>
            <LandingGoals podcasts={trendingPodcasts} />
          </section>
          <section className='app-section-3'>
            <LandingTrending podcasts={trendingPodcasts} />
          </section>

          <section style={{ textAlign: 'center', maxWidth: '900px', margin: 'auto', marginBottom: '60px' }}>
            {/* <FAQ /> */}
          </section>

          {/* <section style={{ textAlign: 'center', maxWidth: '900px', margin: 'auto', marginBottom: '60px' }}>
            <h2 className='serif-bold-extra'>Don’t take our word for it. Read our reviews.</h2>
            <Row className='testimonials' style={{ backgroundImage: `url(${staticAssetsPrefix}/static/images/home/bg-testimonial.svg)` }}>
              <Col xl={12} style={{ margin: 'auto' }}>
                <article>
                  <Testimonial author={testimonials[0].author} comment={testimonials[0].comment} />
                </article>
                <br />
                <article>
                  <Testimonial author={testimonials[2].author} comment={testimonials[2].comment} />
                </article>
              </Col>
              <Col xl={12} style={{ margin: 'auto' }}>
                <article>
                  <Testimonial author={testimonials[1].author} comment={testimonials[1].comment} />
                </article>
              </Col>
            </Row>
          </section> */}
        </main>
        {/* <p>Common footer</p> */}

        <style jsx>
          {`
            .brand-logo img {
              margin: 0 2.5em;
            }
            :global(.all-devices) {
              background-repeat: no-repeat;
              background-position: 100% 20%;
              background-size: contain;
            }
            :global(.testimonials) {
              background-size: 60%;
              background-repeat: no-repeat;
              background-position: 60% 250%;
            }
            .app-section-1 {
              // padding-bottom: ${spacing('xxl')};
              // padding-top: ${spacing('lg')};
              height: 100vh;
              background: black;
              // background: hsla(235.9, 33.7%,22.2%, 1); // #6772e5; // hsla(225.9, 63.7%, 62.2%, 1);
              // clip-path: polygon(5% 5%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
              clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
              // clip-path: ellipse(80% 75% at 60% 20%);
            }
            .app-section-2 {
              padding-top: 2em;
              padding-bottom: ${spacing('xxl')};
            }
            .app-section-3 {
              padding: 2em;
              padding-top: 2em;
            }
            .bg-white {
              background: white;
            }
            .title {
              margin-bottom: ${spacing('sm')};
            }
            .desktop-apps a {
              color: ${colors.third};
            }
            .cards {
              float: left;
            }
            .feature-card{
              // float: left;
              // display: inline-block;
            }
            .feature-card-div {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
            }
            .flex {
              display: flex;
              flex-wrap: wrap;
              // justify-content: space-between;
            }
            .device-img{
              max-width: 100%;
            }
            .landing-svg {
              padding: ${spacing('xl')};
              width: 75%;
              padding-top: 6rem;
              padding-right: 6rem;
            }
            :global(.podcasts-row) {
              margin: 0 8vw 0 8vw;
            }
            // .image {
            //   position: relative;
            //   // width: 400px;
            //   // height: 400px;
            // }
            // .image img {
            //   width: 100%;
            //   vertical-align: top;
            //   filter: brightness(30%);
            // }
            // .image:after {
            //   position: absolute;
            //   width: 100%; height:100%;
            //   top:0; left:0;
            //   background:rgba(0,0,0);
            //   opacity: 1;
            //   transition: all 1s;
            //   -webkit-transition: all 1s;
            // }
            .bg-image {
              right: -10%;
              top: -2%;
            }
            @media ${maxScreenSize.tablet} {
              :global(.podcasts-row) {
                margin: 0 ${spacing()};
              }
              .iphone-image {
                  display: none;
              }
              .desktop-apps {
                display: none;
              }
              .main-title{
                // margin: ${spacing()};
                position: relative;
                top: 30%;
                transform: translateY(0);
                width: 100%;
                margin-left: 0%;
              }
              .main-title h1{
                font-size: 2.5em;
              }
              .landing-svg {
                margin-top: 45%;
                padding: 0;
              }
              .app-section-3 {
                padding: 1em;
              }
            }
            @media ${minScreenSize.laptopL} {
              .main-title{
                  top: 40%;
              }
              .bg-image {
                right: 5%;
                top: -5%;
              }
            }
          `}
        </style>
      </div >
    )
  }
}

Home.propTypes = {
  // loadingState: PropTypes.object,
  // subscribedPodcasts: PropTypes.object,
  // loadingUserDetails: PropTypes.bool,
  fetchError: PropTypes.object,
  trendingPodcasts: PropTypes.object,
  // userDetails: PropTypes.object,
  // episodes: PropTypes.object,
  // fetchLatestSubscribedEpisodes: PropTypes.func,
}

// const mapStateToProps = (state) => {
//   return {
//     episodes: state.latestEpisodes,
//     fetchError: state.errorsState,
//     // userPlaylists: state.userPlaylists,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     // fetchLatestSubscribedEpisodes: bindActionCreators(fetchLatestSubscribedEpisodes, dispatch),
//     // fetchPlaylists: bindActionCreators(fetchPlaylists, dispatch)
//   }
// };

// export default withRedux(initStore, null, null)(Home);
// export default compose(
//   withRedux(initStore, null, null),
//   connect(mapStateToProps, mapDispatchToProps)
// )(Home);

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;